(function () {
	'use strict';
	angular
		.module('app')
		.factory('manageView', [
			'seedcodeCalendar',
			'manageSettings',
			'manageResources',
			'manageConfig',
			'filemakerJS',
			'utilities',
			manageView,
		]);

	function manageView(
		seedcodeCalendar,
		manageSettings,
		manageResources,
		manageConfig,
		filemakerJS,
		utilities
	) {
		return {
			changeView: changeView,
			changeHorizonView: changeHorizonView,
		};

		function changeView(view) {
			var config = seedcodeCalendar.get('config');
			manageSettings.applyViewSettings(config, view, false);

			manageResources.updateResourceColumns(true);
			//if we're in FileMakerJS update our values
			if (
				utilities.getDBKPlatform() &&
				utilities.getDBKPlatform() === 'dbkfmjs'
			) {
				filemakerJS.loadFileMakerJSConfig();
			}

			seedcodeCalendar.get('element').fullCalendar('changeView', view);
		}

		function changeHorizonView(breakoutField, breakout) {
			var view = seedcodeCalendar.get('view');
			var config = seedcodeCalendar.get('config');
			config.horizonBreakoutField = breakoutField || false;
			config.breakout = breakout || false;

			manageConfig.applyLocalSetting('horizonBreakoutField', true, true);
			seedcodeCalendar
				.get('element')
				.fullCalendar(
					'updateOptions',
					'horizonBreakoutField',
					config.horizonBreakoutField,
					false
				);

			manageConfig.applyLocalSetting('breakout', true, true);
			seedcodeCalendar
				.get('element')
				.fullCalendar(
					'updateOptions',
					'breakout',
					config.breakout,
					false
				);

			if (view.name === 'basicHorizon') {
				seedcodeCalendar
					.get('element')
					.fullCalendar('changeView', 'basicHorizon', true);
			} else {
				changeView('basicHorizon');
			}
		}
	}
})();
